import { QueryParams } from '../interfaces/url'

export const useSetQueryParams = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const params: Array<string> = []
    const filters: Array<QueryParams> = []
    const stringQueries: any = {
        functie: 'searchQuery',
        location: 'location',
        city: 'city',
        p: 'p',
        page: 'page',
    }

    for (const value of urlParams.keys()) {
        if (params.indexOf(value) === -1) {
            params.push(value)
        }
    }
    for (const key of params) {
        let objFilter: QueryParams
        if (stringQueries[key] ?? null) {
            objFilter = {
                type: stringQueries[key],
                value: urlParams.get(key) ?? ''
            }
        }  
        else {
            objFilter = {
                type: `${key}`,
                values: []
            }
            for (let i = 0; i < urlParams.getAll(key).length; i++) {
                if (typeof objFilter.values !== 'undefined') {
                    objFilter.values[i] = { name: urlParams.getAll(key)[i] }
                }
            }
        }
        filters.push(objFilter)
    }

    return filters
}

export const useQueryUrlify = (key: string, value: string) => {
    const params = new URLSearchParams(window.location.search)
    const prev = params.get(key)

    if (value == '') {
        const url = new URL(window.location.href)
        url.searchParams.delete(key)
        history.pushState({}, '', url)
    } else {
        //filter
        if (prev) {
            let url = location.href.replace(`${key}=${encodeURIComponent(prev)}`, `${key}=${encodeURIComponent(value)}`)
            url = strip(url)
    
            history.pushState({}, '', url)
        } else {
            // location.href = `${location.href}?${key}=${value}`
            if (location.href.includes('?')) {
                history.pushState({}, '', `${location.href}&${key}=${value}`)
            } else {
                history.pushState({}, '', `${location.href}?${key}=${value}`)
            }
        }
    }
}

export const useResetQuery = () => {
    const url = location.href.split('?')[0]
    history.pushState({}, '', url)
}

export const useSlugUrlify = (value: string): void => {
    const lastSegment = useGetLastSegmentOfSlug(window.location.href)

    if (lastSegment.indexOf('projects?') === 0) {
        //create
        let url = location.href.replace("projects?", `projects/${value}?`)
        url = strip(url)
        history.pushState({}, '', url)
    }
}

export const useQueryArrayUrlify = (key: string, value: string): void => {
    let url = location.href

    if (location.href.includes(`${key}=${encodeURIComponent(value)}`)) {
        url = url.replace(`&${key}=${encodeURIComponent(value)}`, "")
        
        url = url.replace(`${key}=${encodeURIComponent(value)}`, "")
        
        url = url.replace("?&", "?")
        
        if (url.lastIndexOf('?') + 1 === url.length) {
            url = url.replace("?", "")
        }

        url = strip(url)

        history.pushState({}, '', url)
    } else {
        if (url.includes('?')) {
            url = `${url}&${key}=${value}`
            url = strip(url)
            history.pushState({}, '', url)
        } else {
            url = `${url}?${key}=${value}`
            url = strip(url)
            history.pushState({}, '', url)
        }
    }
}

export const useRemoveQueryUrlify = (key: string, value?: string): void => {
    const params = new URLSearchParams(window.location.search)
    const prev = value === null ? params.get(key) : value
    let url = location.href

    if (prev) {
        url = url.replace(`${key}=${encodeURIComponent(prev)}`, "")
        
        if (url.lastIndexOf('?') + 1 === url.length) {
            url = url.slice(0,-1)
        }

        if (url.lastIndexOf('&') + 1 === url.length) {
            url = url.slice(0,-1)
        }

        url = strip(url)

        history.pushState({}, '', url)
    }
}

export const useGetLastSegmentOfSlug = (url: string): string => {
    return url.substring(url.lastIndexOf('/') + 1)
}

const strip = (url: string) => {
    url = url.replace('&&', '&')
    url = url.replace('?&', '?')
    return url
}
